import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"
import Section from "../components/section/section"
import HowItWorks from "../components/partials/how-it-works"
import Fotomaton from "../components/partials/fotomaton"
import HappyPack from "../components/partials/happy-pack"
import Events from "../components/partials/events"
import {t} from "../utils/utils"

const IndexPage = (props) => {
  const {messages} = props.pageContext;
  return(
    <>
      <Layout lang={props.pageContext.lang} messages={messages}>
        
        <SEO title={ t('seo-title', messages) } 
          description={ t('seo-description', messages) }
          link= {
            [
              {
                rel: "canonical",
                href: 'https://happyselfie.es/',
              },
              {
                rel: "alternate",
                href: 'https://happyselfie.es/',
                hreflang: 'es-ES'
              },
              {
                rel: "alternate",
                href: 'https://happyselfie.es/eu/',
                hreflang: 'eu-ES'
              }
            ]
          }
        />
        <Header messages={messages}></Header>
        <Section id="anchor-how-it-works" className="section-how-it-works" 
          title={<h2>{t('nav-1', messages)}</h2>}>
          <HowItWorks messages={messages}/>
        </Section>
        <Section id="anchor-fotomaton" className="section-fotomaton safe-area-expand" 
          title={<h2>{t('nav-2', messages)}</h2>}>
          <Fotomaton messages={messages}/>
        </Section>
        <Section id="anchor-happy-pack" className="section-happy-pack" 
          title={<h2>{t('nav-3', messages)}</h2>}>
          <HappyPack messages={messages}/>
        </Section>
        <Section id="anchor-events" className="section-events" 
          title={<h2>{t('nav-4', messages)}</h2>}>
          <Events lang={props.pageContext.lang} messages={messages}/>
        </Section>
      </Layout>
    </>
  )
}

export default IndexPage
import React from 'react'
import './how-it-works.scss'
import Step1SVG from './../../assets/images/step1.svg'
import Step2SVG from './../../assets/images/step2.svg'
import Step3SVG from './../../assets/images/step3.svg'
import Step4SVG from './../../assets/images/step4.svg'
import ArrowUpSVG from './../../assets/images/arrow-up.svg'
import ArrowDownSVG from './../../assets/images/arrow-down.svg'
import Row from "../grid/row"
import Col from "../grid/col"
import { t } from '../../utils/utils'

const HowItWorks = ({messages}) => {
  return (
    <Row className="how-it-works" wrap={true}>
      <Col md={6} lg={3}>
        <Step1SVG className="step-svg"/>
        <span>1</span>
        <p>{ t("como-1", messages ) }</p>
        <ArrowUpSVG className="arrow-up"/>
      </Col>
      <Col md={6} lg={3}>
        <Step2SVG className="step-svg"/>
        <span>2</span>
        <p>{ t("como-2", messages ) }</p>
        <ArrowDownSVG className="arrow-down"/>
      </Col>
      <Col md={6} lg={3}>
        <Step3SVG className="step-svg"/>
        <span>3</span>
        <p>{ t("como-3", messages ) }</p>
        <ArrowUpSVG className="arrow-up"/>
      </Col>
      <Col md={6} lg={3}>
        <Step4SVG className="step-svg"/>
        <span>4</span>
        <p>{ t("como-4", messages ) }</p>
      </Col>
    </Row>
  )
}

export default HowItWorks
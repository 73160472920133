import React from "react"
import Row from "../grid/row"
import Col from "../grid/col"
import './happy-pack.scss'
import {t} from "../../utils/utils"

const HappyPack = ({messages}) => {
  return (
    <>
      <Row wrap={true}>
        <Col xl={6}>
          <div className="services">
            <p>{t('pack-incluido-title', messages)}</p>
            <ul>
              <li dangerouslySetInnerHTML={{__html: t('pack-incluido-1', messages) }} />
              <li dangerouslySetInnerHTML={{__html: t('pack-incluido-2', messages) }} />
              <li dangerouslySetInnerHTML={{__html: t('pack-incluido-3', messages) }} />
              <li dangerouslySetInnerHTML={{__html: t('pack-incluido-4', messages) }} />
              <li dangerouslySetInnerHTML={{__html: t('pack-incluido-5', messages) }} />
              <li dangerouslySetInnerHTML={{__html: t('pack-incluido-6', messages) }} />
              <li dangerouslySetInnerHTML={{__html: t('pack-incluido-7', messages) }} />
            </ul>
          </div>
        </Col>
        <Col xl={6}>
          <div className="aditional-services">
            <p>{t('pack-adicionales-title', messages)}</p>
            <ul>
              <li dangerouslySetInnerHTML={{__html: t('pack-adicionales-1', messages) }} />
              <li dangerouslySetInnerHTML={{__html: t('pack-adicionales-2', messages) }} />
              <li dangerouslySetInnerHTML={{__html: t('pack-adicionales-4', messages) }} />
              <li dangerouslySetInnerHTML={{__html: t('pack-adicionales-5', messages) }} />
            </ul>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default HappyPack
import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import Row from "../../components/grid/row"
import Col from "../../components/grid/col"
import Img from "gatsby-image"
import './fotomaton.scss'
import {t} from "../../utils/utils"

const Fotomaton = ({messages}) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "fotomaton.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)


  return (
    <Row wrap={true}>
      <Col md={12} lg={8} xl={6}>
        <p dangerouslySetInnerHTML={{ __html: t('fotomaton-description', messages) }} />
      </Col>
      <Col xs={8} xsOffset={2} md={6} mdOffset={3} lg={4} lgOffset={0} xl={3} xlOffset={2}>
        <Img
          fluid={data.file.childImageSharp.fluid}
          alt="El fotomatón"
        />
      </Col>
    </Row>
  ) 
}

export default Fotomaton
import React, {useState} from 'react'
import './bubble.scss'
import {useBubbleImages} from './../../hooks/use-bubble-images'


const animationSideSpeed = ['bubbleSideSmall', 'bubbleSideMedium', 'bubbleSideBig'];
const animationDirection = ['normal', 'reverse'];

const Bubble = ({type}) => {

  const { angry, disgusted, sad, neutral, fear, surprise, happy } = useBubbleImages();

  const bubbleTypes = [
    <div style={{backgroundImage: "url(" + disgusted.fixed.src + ")"}}></div>,
    <div style={{backgroundImage: "url(" + sad.fixed.src + ")"}}></div>,
    <div style={{backgroundImage: "url(" + neutral.fixed.src + ")"}}></div>,
    <div style={{backgroundImage: "url(" + fear.fixed.src + ")"}}></div>,
    <div style={{backgroundImage: "url(" + surprise.fixed.src + ")"}}></div>,
    <div style={{backgroundImage: "url(" + angry.fixed.src + ")"}}></div>,
    <div style={{backgroundImage: "url(" + happy.fixed.src + ")"}}></div>
  ]

  const getRandomState = () => {
    return {
      x: Math.floor(Math.random() * Math.floor(100)),
      duration: Math.random() * Math.floor(4) + 4,
      delay: Math.random() * Math.floor(3),
      animationSideSpeed: animationSideSpeed[Math.random() * Math.floor(2)],
      animationSideDuration:Math.random() * Math.floor(2) + 3,
      animationDirection: animationDirection[Math.floor(Math.random() * Math.floor(1))]
    };
  }

  const [bubbleState] = useState(getRandomState());
  const bubbleType = bubbleTypes[type];

  return (
    <div className="bubble"
      style={{
        left: bubbleState.x + '%',
        animationDuration: bubbleState.duration + 's',
        animationDelay: bubbleState.delay + 's'
      }}>
      <div className="bubble-inner"
        style={{
          animationName: bubbleState.animationSideSpeed,
          animationDirection: bubbleState.animationDirection,
          animationDuration: bubbleState.animationSideDuration + 's'
        }}>
        {bubbleType}
      </div>
    </div>
  )
}

export default Bubble
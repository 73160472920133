import {useStaticQuery, graphql} from 'gatsby'

export const useBubbleImages = () => {
  const data = useStaticQuery(
    graphql`
      query {
        angry: imageSharp(fluid: {originalName: {eq: "angry.png"}}) {
          fixed {
            src
          }
        }
        disgusted: imageSharp(fluid: {originalName: {eq: "disgusted.png"}}) {
          fixed {
            src
          }
        }
        sad: imageSharp(fluid: {originalName: {eq: "sad.png"}}) {
          fixed {
            src
          }
        }
        neutral: imageSharp(fluid: {originalName: {eq: "neutral.png"}}) {
          fixed {
            src
          }
        }
        fear: imageSharp(fluid: {originalName: {eq: "fear.png"}}) {
          fixed {
            src
          }
        }
        surprise: imageSharp(fluid: {originalName: {eq: "surprise.png"}}) {
          fixed {
            src
          }
        }
        happy: imageSharp(fluid: {originalName: {eq: "happy.png"}}) {
          fixed {
            src
          }
        }
      }
    `
  )

  return data;
}
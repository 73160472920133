import React from 'react'
import './header.scss'
import LogoSVG from './../../assets/images/logo.svg'
import Row from "../../components/grid/row"
import Col from "../../components/grid/col"
import Bubble from "../bubble/bubble"
import {t} from "../../utils/utils"

const Header = ({messages}) => {

  const initBubbles = () => {
    const bubbles = []
    for(let i = 0; i < 11; i++) {
      bubbles.push(<Bubble key={i} type={Math.floor(Math.random() * Math.floor(6))} />)
    }
    return bubbles;
  }

  return (
    <header className="site-header safe-area-expand">
      <Row>
        <Col xl={9}>
          <LogoSVG className="brand"/>
          <h1>{ t('main-title', messages) }</h1>
          <p className="claim">{ t('main-subtitle', messages) }</p>
        </Col>
      </Row>
      {initBubbles()}
    </header>
  )
}

export default Header
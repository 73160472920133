import React from "react"
import Row from "../../components/grid/row"
import Col from "../../components/grid/col"
import './events.scss'
import {Link} from 'gatsby'
import {t} from "../../utils/utils"


const Events = ({lang, messages}) => {
  const contactHrefs = {
    'es': '/contacto',
    'eu': '/eu/kontaktua',
    'en': '/en/contact'
  };

  return (
    <>
      <Row>
        <Col md={12} lg={6}>
          <p><Link to={contactHrefs[lang]} role="button">{t('eventos-title', messages)}</Link> {t('eventos-description', messages)}</p>
          <ul>
            <li>{t('eventos-1', messages)}</li>
            <li>{t('eventos-2', messages)}</li>
            <li>{t('eventos-3', messages)}</li>
            <li>{t('eventos-4', messages)}</li>
          </ul>
        </Col>
      </Row>
    </>
  )
}

export default Events